<template>
	
	<el-drawer
	    :visible.sync="drawer"
		title="个人中心"
	  >
	    <el-descriptions title="基本信息"  :column="2" size="large" class="nickname">
			
	        <el-descriptions-item label="昵称" span="2" >
				{{getAdminInfos.real_name}}
				<el-avatar
					:src="getAdminInfos.head_pic"
					:size="80"
					class="avatar"
				/>
			</el-descriptions-item>
			
			
			
			
			
			<el-descriptions-item label="登录账号" span="2" >{{getAdminInfos.account}}</el-descriptions-item>
	        <el-descriptions-item label="最后登录时间" span="2" >{{getAdminInfos.last_time}}</el-descriptions-item>
	        <el-descriptions-item label="最后登录IP" span="2" >{{getAdminInfos.last_ip}}</el-descriptions-item>
	        
	      </el-descriptions>
	</el-drawer>
	
</template>
<script>
export default {
  name: 'adminInfos',
  props:{
  },
  
  components: {
  },
  data() {
    return {
		drawer:false
    };
  },
  computed: {
	// 获取登录信息
	getAdminInfos() {
	  return this.$store.state.login.adminInfo;
	},
  },
  watch: {
	
  },
  created() {
  },
  methods: {
	
	
  },
};
</script>

<style scoped styles="scss">
	
.nickname{
	position: relative;
	padding: 0 15px;
	
}	
	
.avatar{
	position: absolute;
	right: 60px;
	top:0;
	
}
</style>