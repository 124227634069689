
import { createStore } from 'vuex'

import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';

import login from './module/login';
import media from './module/media';
import menus from './module/menus';
import menu from './module/menu';
import themeConfig from './module/themeConfig';
Vue.use(Vuex);


export default new Vuex.Store({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  plugins: [
    new VuexPersistence({
      reducer: (state) => ({
        login: state.login,
		menus:state.menus,
		media: state.media,
		menu:state.menu,
		themeConfig:state.themeConfig
      }),
      storage: window.localStorage,
    }).plugin,
  ],
  modules: {
	  login,
	  menus,
	  menu,
	  media,
	  themeConfig
  }
})
