<template>
	
	
	<div class="layout-navbars-breadcrumb">
		<i
		  class="layout-navbars-breadcrumb-icon"
		  :class="isCollapse ? 'el-icon-s-unfold' : 'el-icon-s-fold'"
		  @click="onThemeConfigChange"
		></i>
		
		<el-drawer :visible.sync="isCollapse" :with-header="false" direction="ltr" size="180px" >
		  <el-aside class="layout-aside w100 h100">
		    <!-- <Logo v-if="setShowLogo && menuList.length" /> -->
		    <el-scrollbar class="flex-auto" ref="layoutAsideRef">
		      <Vertical :menuList="columnsAsideList" />
		    </el-scrollbar>
		  </el-aside>
		</el-drawer>
	</div>
	
	
</template>

<script>
import Vertical from '@/layout/component/navMenu/vertical.vue';
export default {
  name: 'mobileMenus',
  components: {Vertical},
  props: {
    columnsAsideList: Array,
  	activePath:String
  },
  data() {
    return {
	  isCollapse:false
    };
  },
  watch: {
  	// 监听路由的变化
  	$route: {
  	  handler(to) {
  		  this.isCollapse = false
  	  },
  	  deep: true,
  	}
  },
  computed: {
  },
  beforeDestroy() {
  },
  mounted() {
    
  },
  methods: {
    onThemeConfigChange()
	{
		this.isCollapse = !this.isCollapse
	}
  },
};
</script>

<style scoped lang="scss">
.layout-navbars-breadcrumb {
  // flex: 1;
  height: inherit;
  display: flex;
  align-items: center;
  padding-left: 15px;
  .layout-navbars-breadcrumb-icon {
    cursor: pointer;
    font-size: 18px;
    margin-right: 15px;
    color: var(--prev-bg-topBarColor);
    opacity: 0.8;
    &:hover {
      opacity: 1;
    }
  }
}
</style>