import draggable from './module/draggable';
import clipboard from './module/clipboard';
import auth from './module/auth';

const directives = {
  draggable,
  clipboard,
  auth
};

export default directives;
