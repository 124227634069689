

import request from '@/libs/request';

export function ajCaptcha(params) {
  return request({
    url: 'ajcaptcha',
    method: 'get',
    params: params,
  });
}

export function ajCaptchaCheck(data) {
  return request({
    url: 'ajcheck',
    method: 'post',
    data: data,
  });
}


/**
 * @description 表格--删除
 * @param {Number} param id {Number} 配置id
 */
export function tableDelApi(data) {
  return request({
    url: data.url,
    method: data.method,
    data: data.ids,
    kefu: data.kefu || '',
  });
}


/**
 * 城市列表
 */
export function cityList() {
  return request({
    url: `setting/city/full_list`,
    method: 'GET',
  });
}

export function paymentTypeList() {
  return request({
    url: `payment_type`,
    method: 'GET',
  });
}
