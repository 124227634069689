import { render, staticRenderFns } from "./userInfo.vue?vue&type=template&id=49007585&scoped=true"
import script from "./userInfo.vue?vue&type=script&lang=js"
export * from "./userInfo.vue?vue&type=script&lang=js"
import style0 from "./userInfo.vue?vue&type=style&index=0&id=49007585&prod&scoped=true&styles=scss&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49007585",
  null
  
)

export default component.exports